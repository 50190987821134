<template>
    <Modal class="application-form size-xl" v-if="opened" @close="closeForm()" :opened="opened" :close_click_outside="false">
        <template v-slot:header>
            Nová žádost o přijetí
        </template>
        <template v-slot:body>
            <ApplicationFormPage1 v-if="page === 1" @nextPage="page++" @closeForm="closeForm()" />
            <ApplicationFormPage2 v-if="page === 2" @nextPage="page++" @goBack="page--" @closeForm="closeForm()"/>
            <ApplicationFormPage3 v-if="page === 3" @reset="setDefault()" @goBack="page--" @closeForm="closeForm()"/>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";

// import ApplicationFormPage1 from './ApplicationFormPage1';
// import ApplicationFormPage2 from './ApplicationFormPage2';
import useFormData from '@common/composable/BaseFormData';
import { defineAsyncComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "ApplicationForm",
    components: {
        Modal,        
        ApplicationFormPage1: defineAsyncComponent(() => import(/* webpackChunkName: "application-form-page1" */ './ApplicationFormPage1')),
        ApplicationFormPage2: defineAsyncComponent(() => import(/* webpackChunkName: "application-form-page2" */'./ApplicationFormPage2')),
        ApplicationFormPage3: defineAsyncComponent(() => import(/* webpackChunkName: "application-form-page3" */'./ApplicationFormPage3')),
    },
    props: {
        opened: Boolean,
    },
    emits: ['close'],
    setup(props,context) { 
        const { data, } = useFormData();

        const store = useStore();

        const page = ref(1);

        const setDefault = () => {
            data.value = {
                bydliste_PSC: '36301',
                bydliste_mesto: 'Ostrov'
            }
            page.value = 1;
            store.commit('application/setApplied',false)
            store.commit('application/setCanApply',false)
        }
        
        watchEffect(() => {
            if(props.opened){
                setDefault();
            }
        });

        const closeForm = () => {
            // setDefault();
            context.emit('close');
        }
        setDefault();


        return {
            page,
            closeForm,
            setDefault
        }
    }
}
</script>

<style scoped>
/* ::v-deep(.base-form){
    margin-left: auto;
    margin-right: auto;
} */
</style>
